import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { FirebaseAuthContext } from "../../context/firebase-auth";
import { getRequestConfigWithUserToken, sendPostRequest, toastError, toastSuccess } from "../../util/util";
import Tag, { instanceOfTag } from "shared-models/build/tag";

const Tags = () => {
    const user = useContext(FirebaseAuthContext);
    const [tags, setTags] = useState<Tag[]>([]);

    useEffect(() => {
        if (user !== null) {
            getTags().catch(console.error);
        }
    }, [user]);


    const getTags = async () => {
        // Get tags
        if (user === null) return;
        const [data, error] = await sendPostRequest(user, "/tags/get-tags", {}, "", "Failed to retrieve tags");
        if (error === null) {
            setTags(data);
        } else {
            console.log(error);
        }
    }

    return (
        <div>
            <div className="tw-my-8">
                <h3 className="tw-font-bold tw-text-lg">
                Tags Displayed Below:
                </h3>
            </div>
            {
                tags.length > 0 ?
                <div className="tw-grid tw-grid-cols-4 md:tw-grid-cols-7 tw-gap-4 tw-items-center tw-mb-4 tw-font-bold">
                    <div>Id</div>
                    <div>Name</div>
                    <div>Description</div>
                    <div>Picture URL</div>
                    <div>Settings</div>
                    <div>Verified</div>
                    <div>Action</div>
                </div>
                :
                null
            }
            {
                tags.length === 0 ?
                <p>No tags to show</p>
                :
                tags.map((tag) => {
                    if (!instanceOfTag(tag)) return null;
                    return (
                        <div className="tw-grid tw-grid-cols-4 md:tw-grid-cols-7 tw-gap-4 tw-items-center tw-py-4 tw-border-b-2">
                            <div className="">
                                {tag.Id}
                            </div>
                            <div>
                                {tag.Name}
                            </div>
                            <div>
                                {tag.Description}
                            </div>
                            <div>
                                <a href={tag.TagPictureUrl} target="_blank" rel="noreferrer" className="tw-break-words">
                                {tag.TagPictureUrl}
                                </a>
                            </div>
                            <div>
                                {JSON.stringify(tag.Settings)}
                            </div>
                            <div>
                                {tag.Verified ? "yes" : "no" }
                            </div>
                            <div>
                                <a href={`/admin-team/modify-tags?data=${encodeURIComponent(JSON.stringify(tag))}`}>
                                    <button className="tw-white-button">
                                    Modify Tag
                                    </button>
                                </a>
                            </div>
                        </div>
                    )
                })
            }

            <div className="tw-mt-12">
            <a className="tw-primary-button" href={`/admin-team/modify-tags`}>
                Add Tag
            </a>
            </div>
        </div>
    )
};

export default Tags;